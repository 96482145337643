<template>
    <div>
        <el-card>
            <template #header>
                <div style="text-align:left">
                    商户入驻
                </div>
            </template>
            <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
                
                    <el-tab-pane label="全部" name="first">
                        <el-scrollbar height="600px">
                            <div class="content">
                                <span style="font-size:14px;margin-right:10px;">企业名称:</span>
                                <!-- <el-select v-model="status" style="margin-right:10px;width:120px;" size="small">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="待审核" value="1"></el-option>
                                    <el-option label="审核通过" value="2"></el-option>
                                    <el-option label="审核不通过" value="3"></el-option>
                                </el-select> -->
                                
                                <el-input v-model="keyword" placeholder="企业名称" prefix-icon="el-icon-search" class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
                                <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down" size="small">搜索</el-button>
                            </div>
                            <div class="content-main">
                                <el-table :data="tabledata" border style="width: 100%; margin: 1em 0"
                                                v-loading="load" 
                                                element-loading-spinner="el-icon-loading" 
                                                element-loading-text="加载中..."
                                                :default-sort = "{prop: 'AddDTime', order: 'descending'}"
                                                size="small">
                                    <el-table-column label="企业信息" prop="qiye">
                                        <template #default="scope">
                                            企业名称：<text>{{scope.row.qiye}}</text><br/>
                                            社会信用代码：<text>{{scope.row.xinyong}}</text><br/>
                                            线上店铺名称：<text>{{scope.row.dianpu}}</text>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="营业执照" prop="zhizhao" >
                                        <template #default="scope">
                                            <el-image style="width: 100px; height: 100px" :preview-src-list="[scope.row.zhizhao]" :src="scope.row.zhizhao"  />
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="法人信息" prop="faren">
                                        <template #default="scope">
                                            法人联系方式：<text>{{scope.row.lianxi}}</text><br/>
                                            法人身份证：<text>{{scope.row.shenfen}}</text><br/>
                                            电话：<text>{{scope.row.lianxi}}</text>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="身份证照片" prop="zhengmian" >
                                        <template #default="scope">
                                            人像面：<el-image style="width:100px;height:50px" :preview-src-list="[scope.row.zhengmian]" :src="scope.row.zhengmian" /><br/>
                                            国徽面：<el-image style="width:100px;height:50px" :preview-src-list="[scope.row.beimian]" :src="scope.row.beimian" />
                                        </template>
                                        
                                    </el-table-column>
                                    <el-table-column label="套餐信息" prop="Status">
                                        <template #default='scope'>
                                            套餐：<text>{{scope.row.taocan}}</text><br/>
                                            总价：<text>{{scope.row.jiage * 12}}(元)</text><br/>
                                            期数：12<el-tag style="cursor:pointer;" v-if="scope.row.status == 2" @click="detail(scope.row.id,scope.row.status)" size="small">详情</el-tag><br/>
                                            <text v-if="scope.row.status == 2">已付：{{scope.row.total}}/{{scope.row.jiage * 12}}(元)</text><br/>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="状态" prop="status">
                                        <template #default='scope'>
                                            <span v-if="scope.row.status == 1" style="color:red">待审核</span>
                                            <span v-if="scope.row.status == 2" style="color:#67c23a">审核通过</span>
                                            <span v-if="scope.row.status == 3" style="color:#67c23a">审核不通过</span>
                                        </template>
                                    </el-table-column>
                                    
                                    
                                    <el-table-column label="操作" align="left">
                                        <template #default='scope'>
                                            <el-button size="small" v-if="scope.row.status == 1"  @click="DisableMerchant(scope.row.id,scope.row)" type="text">审核</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-pagination @current-change="handleCurrentChange"
                                                @size-change="handleSizeChange"
                                                :current-page="curpage"
                                                :page-size="pagesize"
                                                :page-sizes="[10,20,30,50,100]"
                                                layout="sizes,total, prev, pager, next, jumper"
                                                :total="totalcount"
                                                :hide-on-single-page="false"
                                                style="text-align:center"></el-pagination>
                            </div>
                        </el-scrollbar>
                    </el-tab-pane>
                
                <el-tab-pane label="待审核" name="second">
                    <el-scrollbar height="600px">
                        
                        <div class="content-main">
                            <el-table :data="tabledata" border style="width: 100%; margin: 1em 0"
                                            v-loading="load" 
                                            element-loading-spinner="el-icon-loading" 
                                            element-loading-text="加载中..."
                                            :default-sort = "{prop: 'AddDTime', order: 'descending'}"
                                            size="small">
                                <el-table-column label="企业信息" prop="qiye">
                                    <template #default="scope">
                                        企业名称：<text>{{scope.row.qiye}}</text><br/>
                                        社会信用代码：<text>{{scope.row.xinyong}}</text><br/>
                                        线上店铺名称：<text>{{scope.row.dianpu}}</text>
                                    </template>
                                </el-table-column>
                                <el-table-column label="营业执照" prop="zhizhao" >
                                    <template #default="scope">
                                        <el-image style="width: 100px; height: 100px" :preview-src-list="[scope.row.zhizhao]" :src="scope.row.zhizhao"  />
                                    </template>
                                </el-table-column>
                                <el-table-column label="法人信息" prop="faren">
                                    <template #default="scope">
                                        法人联系方式：<text>{{scope.row.lianxi}}</text><br/>
                                        法人身份证：<text>{{scope.row.shenfen}}</text><br/>
                                        电话：<text>{{scope.row.lianxi}}</text>
                                    </template>
                                </el-table-column>
                                <el-table-column label="身份证照片" prop="zhengmian" >
                                    <template #default="scope">
                                        人像面：<el-image style="width:100px;height:50px" :preview-src-list="[scope.row.zhengmian]" :src="scope.row.zhengmian" /><br/>
                                        国徽面：<el-image style="width:100px;height:50px" :preview-src-list="[scope.row.beimian]" :src="scope.row.beimian" />
                                    </template>
                                    
                                </el-table-column>
                                <el-table-column label="套餐信息" prop="Status">
                                    <template #default='scope'>
                                        套餐：<text>{{scope.row.taocan}}</text><br/>
                                        总价：<text>{{scope.row.jiage*12}}(元)</text><br/>
                                        期数：12<el-tag style="cursor:pointer;" v-if="scope.row.status == 2" @click="detail(scope.row.id,scope.row.status)" size="small">详情</el-tag><br/>
                                    </template>
                                </el-table-column>
                                <el-table-column label="状态" prop="status">
                                    <template #default='scope'>
                                        <span v-if="scope.row.status == 1" style="color:red">待审核</span>
                                        <span v-if="scope.row.status == 2" style="color:#67c23a">审核通过</span>
                                        <span v-if="scope.row.status == 3" style="color:#67c23a">审核不通过</span>
                                    </template>
                                </el-table-column>
                                
                                
                                <el-table-column label="操作" align="left">
                                    <template #default='scope'>
                                        <el-button size="small" v-if="scope.row.status == 1" @click="DisableMerchant(scope.row.id,scope.row)" type="text">审核</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-pagination @current-change="handleCurrentChange"
                                            @size-change="handleSizeChange"
                                            :current-page="curpage"
                                            :page-size="pagesize"
                                            :page-sizes="[10,20,30,50,100]"
                                            layout="sizes,total, prev, pager, next, jumper"
                                            :total="totalcount"
                                            :hide-on-single-page="false"
                                            style="text-align:center"></el-pagination>
                        </div>
                    </el-scrollbar>
                </el-tab-pane>
                <el-tab-pane label="审核通过" name="third">
                    <el-scrollbar height="600px">
                       
                        <div class="content-main">
                            <el-table :data="tabledata" border style="width: 100%; margin: 1em 0"
                                            v-loading="load" 
                                            element-loading-spinner="el-icon-loading" 
                                            element-loading-text="加载中..."
                                            :default-sort = "{prop: 'AddDTime', order: 'descending'}"
                                            size="small">
                                <el-table-column label="企业信息" prop="qiye">
                                    <template #default="scope">
                                        企业名称：<text>{{scope.row.qiye}}</text><br/>
                                        社会信用代码：<text>{{scope.row.xinyong}}</text><br/>
                                        线上店铺名称：<text>{{scope.row.dianpu}}</text>
                                    </template>
                                </el-table-column>
                                <el-table-column label="营业执照" prop="zhizhao" >
                                    <template #default="scope">
                                        <el-image style="width: 100px; height: 100px" :preview-src-list="[scope.row.zhizhao]" :src="scope.row.zhizhao"  />
                                    </template>
                                </el-table-column>
                                <el-table-column label="法人信息" prop="faren">
                                    <template #default="scope">
                                        法人联系方式：<text>{{scope.row.lianxi}}</text><br/>
                                        法人身份证：<text>{{scope.row.shenfen}}</text><br/>
                                        电话：<text>{{scope.row.lianxi}}</text>
                                    </template>
                                </el-table-column>
                                <el-table-column label="身份证照片" prop="zhengmian" >
                                    <template #default="scope">
                                        人像面：<el-image style="width:100px;height:50px" :preview-src-list="[scope.row.zhengmian]" :src="scope.row.zhengmian" /><br/>
                                        国徽面：<el-image style="width:100px;height:50px" :preview-src-list="[scope.row.beimian]" :src="scope.row.beimian" />
                                    </template>
                                    
                                </el-table-column>
                                <el-table-column label="套餐信息" prop="Status">
                                    <template #default='scope'>
                                        套餐：<text>{{scope.row.taocan}}</text><br/>
                                        总价：<text>{{scope.row.jiage}}</text><br/>
                                        期数：12<el-tag style="cursor:pointer;" @click="detail(scope.row.id)" size="small">详情</el-tag><br/>
                                        已付：{{scope.row.total}}/{{scope.row.jiage * 12}}元<br/>
                                    </template>
                                </el-table-column>
                                <el-table-column label="状态" prop="status">
                                    <template #default='scope'>
                                        <span v-if="scope.row.status == 1" style="color:red">待审核</span>
                                        <span v-if="scope.row.status == 2" style="color:#67c23a">审核通过</span>
                                        <span v-if="scope.row.status == 3" style="color:#67c23a">审核不通过</span>
                                    </template>
                                </el-table-column>
                                
                                
                                <el-table-column label="操作" align="left">
                                    
                                </el-table-column>
                            </el-table>
                            <el-pagination @current-change="handleCurrentChange"
                                            @size-change="handleSizeChange"
                                            :current-page="curpage"
                                            :page-size="pagesize"
                                            :page-sizes="[10,20,30,50,100]"
                                            layout="sizes,total, prev, pager, next, jumper"
                                            :total="totalcount"
                                            :hide-on-single-page="false"
                                            style="text-align:center"></el-pagination>
                        </div>
                    </el-scrollbar>
                </el-tab-pane>
                <el-tab-pane label="审核不通过" name="fourth">
                    <el-scrollbar height="600px">
                        
                        <div class="content-main">
                            <el-table :data="tabledata" border style="width: 100%; margin: 1em 0"
                                            v-loading="load" 
                                            element-loading-spinner="el-icon-loading" 
                                            element-loading-text="加载中..."
                                            :default-sort = "{prop: 'AddDTime', order: 'descending'}"
                                            size="small">
                                <el-table-column label="企业信息" prop="qiye">
                                    <template #default="scope">
                                        企业名称：<text>{{scope.row.qiye}}</text><br/>
                                        社会信用代码：<text>{{scope.row.xinyong}}</text><br/>
                                        线上店铺名称：<text>{{scope.row.dianpu}}</text>
                                    </template>
                                </el-table-column>
                                <el-table-column label="营业执照" prop="zhizhao" >
                                    <template #default="scope">
                                        <el-image style="width: 100px; height: 100px" :preview-src-list="[scope.row.zhizhao]" :src="scope.row.zhizhao"  />
                                    </template>
                                </el-table-column>
                                <el-table-column label="法人信息" prop="faren">
                                    <template #default="scope">
                                        法人联系方式：<text>{{scope.row.lianxi}}</text><br/>
                                        法人身份证：<text>{{scope.row.shenfen}}</text><br/>
                                        电话：<text>{{scope.row.lianxi}}</text>
                                    </template>
                                </el-table-column>
                                <el-table-column label="身份证照片" prop="zhengmian" >
                                    <template #default="scope">
                                        人像面：<el-image style="width:100px;height:50px" :preview-src-list="[scope.row.zhengmian]" :src="scope.row.zhengmian" /><br/>
                                        国徽面：<el-image style="width:100px;height:50px" :preview-src-list="[scope.row.beimian]" :src="scope.row.beimian" />
                                    </template>
                                    
                                </el-table-column>
                                <el-table-column label="套餐信息" prop="Status">
                                    <template #default='scope'>
                                        套餐：<text>{{scope.row.taocan}}</text><br/>
                                        总价：<text>{{scope.row.jiage}}</text><br/>
                                        期数：12<el-tag style="cursor:pointer;" v-if="scope.row.status == 2" @click="detail(scope.row.id)" size="small">详情</el-tag><br/>
                                    </template>
                                </el-table-column>
                                <el-table-column label="状态" prop="status">
                                    <template #default='scope'>
                                        <span v-if="scope.row.status == 1" style="color:red">待审核</span>
                                        <span v-if="scope.row.status == 2" style="color:#67c23a">审核通过</span>
                                        <span v-if="scope.row.status == 3" style="color:#67c23a">审核不通过</span>
                                    </template>
                                </el-table-column>
                                
                                
                                <el-table-column label="审核不通过原因" prop="reason" align="left">
                                    <template #default="scope">
                                        <text>{{scope.row.reason}}</text>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-pagination @current-change="handleCurrentChange"
                                            @size-change="handleSizeChange"
                                            :current-page="curpage"
                                            :page-size="pagesize"
                                            :page-sizes="[10,20,30,50,100]"
                                            layout="sizes,total, prev, pager, next, jumper"
                                            :total="totalcount"
                                            :hide-on-single-page="false"
                                            style="text-align:center"></el-pagination>
                        </div>
                    </el-scrollbar>
                </el-tab-pane>
                
            </el-tabs>
            
        </el-card>
    </div>
    <!--还款详情-->
    <el-dialog
    v-model="huankuan"
    title="还款详情"
    center
    width="55%"
    :before-close="handleClose"
  >
    <el-table :data="huankuandetail"  style="width: 100%">
    <el-table-column type="index" label="期数" :index="indexMethod" width="80" />
    <el-table-column prop="PayDate" label="预计扣款日" width="100" />
    <el-table-column prop="PayMoney" label="账单金额(元)" width="100" />
    <el-table-column prop="address" label="实还金额(元)" width="100"/>
    <el-table-column prop="address" label="未还金额(元)" width="100"/>
    <el-table-column prop="Status" label="还款情况" >
        <template #default="scope">
            <text v-if="scope.row.Status == 0">未扣款</text>
            <text v-if="scope.row.Status == 1">已扣款</text>
            <text v-if="scope.row.Status == 3">扣款失败</text>
        </template>    
    </el-table-column>
    <el-table-column prop="UpdateTime" label="实际扣款日" width="160"/>
    <el-table-column prop="" label="本期结清方式" width="150" />
    <el-table-column  label="操作" width="180">
        <template #default="scope">
            <el-button type="primary" v-if="scope.row.Status == 0" disable="false" @click="handmoney(scope.row.ID,scope.row.PayMoney,scope.row)" size="small">手动扣款</el-button>
            <el-tag class="ml-2" v-if="scope.row.Status == 2" type="info">扣款处理中...</el-tag>
            <el-tag class="ml-2"  type="success" v-if="scope.row.Status ==  1">扣款成功</el-tag>
            <el-tag class="ml-2" type="danger" v-if="scope.row.Status == 3">扣款失败<el-tag @click="handmoney(scope.row.ID,scope.row.PayMoney,scope.row)">重新扣款</el-tag></el-tag>
        </template>
    </el-table-column>
  </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="huankuan = false">关闭</el-button>
      </span>
    </template>
  </el-dialog>
  <!--还款详情-->
  <!--审核-->
  <el-dialog
    v-model="shenhe"
    title="审核"
    width="30%"
  >
    <el-radio-group v-model="radio" @click="tongguo">
        <el-radio label="2">通过</el-radio>
        <el-radio label="3">不通过</el-radio>
    </el-radio-group>
    <el-input
    style="margin-top:10px"
        v-if="textarea"
        v-model="text"
        :rows="2"
        type="textarea"
        placeholder="请输入不通过的原因"
    />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="shenhe = false">关闭</el-button>
        <el-button type="primary" @click="shenheclick"
          >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!--审核-->
</template>
<style scoped>

</style>
<script>

import constant from "@/constant"
import { ref } from "vue";
import { ElMessage } from 'element-plus'
export default {
    data(){
        return {
            text:ref(""),
            radio : ref('2'),
            textarea :ref(false),
            shenhe:ref(false),
            id:"",
            mid :ref(""),
            row:ref(""),
            huankuandetail:ref(""),
            huankuan:ref(false),
            activeName:ref('first'),
            tabledata:[],
            keyword:'',
            status:ref(''),
            curpage:'1',
            pagesize:'10',
            totalcount:"",
        }
    },
    methods:{
        shenheclick(){
            console.log(this.row)
            this.axios.get(constant.shenhe,{
                headers:{
                    "Content-Type":"application/json"
                },
                params:{
                    radio:this.radio,
                    text:this.text,
                    item:JSON.stringify(this.row)
                }
            }).then((response)=>{
                console.log(response.data)
                if(response.data.data == 1){
                    this.shenhe = false
                    ElMessage({
                        message:"审核成功",
                        type:"success"
                    })
                    
                }
            })
        },
        tongguo(){
            console.log(this.radio)
            if(this.radio == 3){
                this.textarea = false
            }else{
                this.textarea = true
            }
        },
        DisableMerchant(id,row){
            this.shenhe = true
            this.row = row
            this.mid = id
            console.log(id)
        },
        handmoney(ID,money,row){
            console.log(money)
            console.log(ID,this.id,row)
            row.Status = 2
            this.axios.get(constant.handmoney,{
                headers:{
                    "Content-Type":"application/json"
                },
                params:{
                    id:this.id,
                    money:money,
                    ID:ID
                }
            }).then((response)=>{
                console.log(response)
                if(response.data.code == 10000){
                    ElMessage({
                        message: '手动扣款成功('+response.data.msg+')',
                        type: 'success',
                    })
                    row.Status = 1
                }else{
                    ElMessage({
                        message:"扣款失败("+response.msg+")",
                        type:'waring'
                    })
                    row.Status = 3
                }
            })
        },
        indexMethod  (index) {
            return "第"+(index+1)+"期" 
        },
        handleClose(){
            this.huankuan = false
        },
        detail(id){
            console.log(id)
            this.id = id
            this.huankuan = true
            this.axios.get(constant.huankuandetail,{
                headers:{
                    "Content-Type":"application/json"
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data)
                this.huankuandetail = response.data
            })
        },
        handleClick (tab){
            let name = tab.props.name
            if(name == 'second'){
                this.status = 1
            }else if(name == 'third'){
                this.status = 2
            }else if(name == 'fourth'){
                this.status = 3
            }else if(name == 'first'){
                this.status = ''
            }
            this.init();
        },
        handleCurrentChange(val){
            console.log(val);
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val){
            console.log(val);
            this.pagesize = val
            this.init();
        },
        init(){
            this.axios.get(constant.merchantruzhu,{
                headers:{
                    "Content-Type":"application/json"
                },
                params:{
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                    keyword: this.keyword,
                    status: this.status,
                }
            }).then((response)=>{
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage*1;
                this.totalcount = response.data.totalcount*1;
            });
        }
    },
    created:function(){
        this.init();
    }
}
</script>